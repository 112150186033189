const menuButton = document.getElementById('js-menu')
const closeButton = document.getElementById('js-menu-close')
const globalnav = document.getElementById('js-globalpcnav')
const background = document.getElementById('js-globalnav-background')
const kfgMenuButton = document.getElementById('kfg-header-menu-button')
const body = document.body

menuButton.addEventListener('click', () => {
  globalnav.classList.toggle('hidden-globalnav')
  background.classList.toggle('hidden-globalnav')
  closeButton.classList.toggle('hidden-globalnav')
  body.classList.add('fixed')
  document.getElementById("kfg-modal").classList.remove("kfgm-open");
})

closeButton.addEventListener('click', () => {
  globalnav.classList.toggle('hidden-globalnav')
  background.classList.toggle('hidden-globalnav')
  closeButton.classList.toggle('hidden-globalnav')
  body.classList.remove('fixed')
})

background.addEventListener('click', () => {
  globalnav.classList.toggle('hidden-globalnav')
  background.classList.toggle('hidden-globalnav')
  closeButton.classList.toggle('hidden-globalnav')
  body.classList.remove('fixed')
})

// kfgMenuButton.addEventListener('click', () => {
//   if (body.classList.contains('fixed') == true) {
//     globalnav.classList.add('hidden-globalnav')
//     background.classList.add('hidden-globalnav')
//     closeButton.classList.toggle('hidden-globalnav')
//     body.classList.remove('fixed')
//   }
// })
